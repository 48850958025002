.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.char-daily {
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media print {
  .chart {
    margin-right: 42px;
    /* border-right-color: red; */
  }
  .main-react-chart {
    margin-left: -20px;
  }
  tfoot.report-footer-sch-fee-print {
    display: table-footer-group;
  }

  div.footer-info-sch-fee-print,
  div.page-footer-sch-fee-print {
    display: none;
    height: 40px;
  }
  .page-footer-line-sch-fee-print {
    border: 2px solid blue;
    margin-bottom: 7px;
    width: 86.5%;
  }

  div.page-footer-text-sch-fee-print {
    text-align: center;
    color: blue;
    font-family: "Khmer OS Siemreap";
    font-size: 10px !important;
  }
  table.report-container-sch-fee-print {
    page-break-after: always;
    width: 100% !important;
    /* display: table;
    overflow-x: auto; */
    /* white-space: nowrap; */
    border-spacing: 0px !important;
  }

  div.page-footer-sch-fee-print {
    text-align: center;
    height: 50px;
    font-size: 10px;
    opacity: 0.8;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-right: 20px;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: KantumruyPro;
  src: url("./Assets/font/KantumruyPro-Regular.ttf");
}
@font-face {
  font-family: KhmerOsMoul;
  src: url("./Assets/font/KhmerOSmuollight.ttf");
}
@font-face {
  font-family: GothicBoldRegular;
  src: url("./Assets/font/GothicBoldRegular.ttf");
}
@font-face {
  font-family: KhmerOSsiemreap;
  src: url("./Assets/font/KhmerOSsiemreap.ttf");
}

/* ======================================== report style ===================================== */
.setup-report-container {
  padding-top: 20px;
  text-align: center;
  font-size: 50px;
  font-family: Bayon !important;
  font-weight: bold;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-company {
  width: 70px;
  /* height: 150px; */
}

.report-top-title {
  text-align: center !important;
  font-size: 12px !important;
  color: #000 !important;
  font-family: Siemreap !important;
}

.report-container {
  width: 100%;
  padding: 10px 20px;
}

.report-header-row {
  background-color: #0f80c2 !important;
  padding: 6px 20px !important;
  /* border: 1px solid #0F80C2 !important; */
  border: none !important;
  border-radius: 6px !important;
}

.report-header-title-start {
  background-color: #0f80c2 !important;
  border-radius: 6px 0px 0px 6px !important;
  padding: 8px 0px 8px 20px !important;
  font-size: 12px !important;
  font-family: Siemreap !important;
  color: #fff !important;
  /* border-top: 1px solid #0F80C2 !important;
  border-bottom: 1px solid #0F80C2 !important; */
  border: none !important;
}

.report-header-title {
  background-color: #0f80c2 !important;
  padding: 8px 0px !important;
  font-size: 12px !important;
  font-family: Siemreap !important;
  color: #fff !important;
  /* border: 1px solid #0F80C2 !important; */
  border: none !important;
  text-align: left;
}

.report-header-title-end {
  background-color: #0f80c2 !important;
  border-radius: 0px 6px 6px 0px !important;
  padding: 8px 20px 8px 0px !important;
  font-size: 12px !important;
  font-family: Siemreap !important;
  color: #fff !important;

  /* border-top: 1px solid #0F80C2 !important;
  border-bottom: 1px solid #0F80C2 !important; */
  border: none !important;
}

.report-body-cell-bottom {
  color: #000 !important;
  font-weight: bold !important;
  border: none !important;
  font-size: 11px !important;
  font-family: Siemreap !important;
  padding: 8px 0px !important;
}

.report-body-cell-bottom-start {
  color: #0f80c2 !important;
  border: none !important;
  font-size: 11px !important;
  font-family: Siemreap !important;
  padding: 8px 0px !important;
}

.report-body-cell-start {
  padding: 8px 0px 8px 20px !important;
  font-size: 11px !important;
  font-family: Siemreap !important;
}

.report-body-cell {
  font-family: Siemreap !important;
  padding: 8px 0px !important;
  font-size: 11px !important;
}

.report-body-cell-end {
  font-family: Siemreap !important;
  font-size: 11px !important;
  padding: 8px 0px !important;
}

.report-body-row {
  padding: 6px 20px !important;
}

/* ====================== Style report warning employee of HR ============================== */
th {
  font-weight: normal;
}

div.page-footer-hr {
  text-align: center;
  height: 50px;
  font-size: 10px;
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  width: 100%;
}

div.page-footer-hr p {
  margin: 0;
}

.watermark {
  display: none;
  top: 50vh;
  z-index: -9;
  width: 50vw;
  page-break-after: always;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
}

table.report-container-hr {
  page-break-after: always;
  width: 100% !important;
  /* display: table;
  overflow-x: auto; */
  /* white-space: nowrap; */
  border-spacing: 0px !important;
}

thead.report-header-hr {
  display: table-header-group;
}

tfoot.report-footer-hr {
  display: table-footer-group;
}

tbody.report-content-hr {
  width: 100% !important;
}

div.footer-info-hr,
div.page-footer-hr {
  display: none;
  height: 40px;
}

.page-footer-line-hr {
  border: 2px solid blue;
  margin-bottom: 7px;
}

div.page-footer-text-hr {
  text-align: center;
  color: blue;
  font-family: "Khmer OS Siemreap";
}

/* ========== style head table ========== */
.header-title-hr {
  font-family: "Khmer OS Muol Light";
  text-align: center;
  font-size: 10px !important;
  letter-spacing: normal !important;
  background-color: #deeffd;
  font-weight: 100 !important;
  padding: 4px;
  border: 0.2px solid #92cbf9 !important;
}

/*==============================Start certificate enrollment student =================================================================*/

.logo-school-image {
  margin-top: 18px !important;
  width: 125px !important;
  height: auto !important;
}

.number-certificate {
  font-family: "Khmer OS Siemreap" !important;
  font-size: 14px !important;
}

.national-certificate-En {
  text-align: center !important;
  font-weight: 100 !important;
  /* font-family: "Century Gothic" !important;
  font-size: 12px !important; */
  font-family: GothicBoldRegular !important;
  font-size: 14px !important;
  line-height: 180% !important;
}

.national-certificate-kh {
  text-align: center !important;
  font-weight: 100 !important;
  font-family: "Khmer OS Muol Light" !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
}

.tackteng-font-cert {
  display: flex !important;
  justify-content: center !important;
}

.image-tackteng-cert {
  width: 140px !important;
}

.title-certificate {
  text-align: center !important;
  font-weight: 100 !important;
  font-family: "Khmer OS Muol Light" !important;
  font-size: 16px !important;
  letter-spacing: normal !important;
}

.body-certificate {
  font-family: "Khmer OS Siemreap" !important;
  font-size: 16px !important;
}

.body-certificate-bold {
  font-family: "Khmer OS Siemreap" !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.footer-certificate-name {
  font-size: 16px !important;
  font-family: "Khmer OS Muol Light" !important;
  font-weight: 100 !important;
  margin-top: 60px !important;
  margin-left: 60px !important;
  text-align: center;
}

.note-certificate-bold {
  font-size: 13px !important;
  font-family: "Khmer OS Muol Light" !important;
}

.note-certificate {
  font-size: 13px !important;
  font-family: "Khmer OS Siemreap" !important;
}

.page-footer-certificate {
  text-align: center;
  /* height: 50px; */
  font-size: 10px;
  /* margin-top: 150px !important; */
  /* opacity: 0.8;
  position: fixed; */
  /* bottom: 0; */
  width: 100%;
}

.page-footer-certificate p {
  margin: 0;
}

.page-footer-line-certificate {
  border: 2px solid #000;
  margin-bottom: 7px;
}

.page-footer-text-certificate {
  text-align: center;
  color: #000;
  font-family: "Khmer OS Siemreap";
}

.page-footer-text-certificate {
  text-align: center;
  color: #000;
  font-family: "Khmer OS Siemreap";
}

.footer-info-certificate {
  margin-bottom: 10px !important;
}

.btn-print-cert {
  background-color: #0f81c2 !important;
  color: #fff !important;
}

/* ==================Monthly result====================== */
.result-header {
  margin-top: 20px;
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 15px !important;
}
.logo-size {
  margin-top: 18px !important;
  width: 130px !important;
}

.program-title {
  /* text-align: center; */
  margin-top: 5px !important;
  font-family: KhmerOsMuol !important;
  font-size: 12px !important;
  /* letter-spacing: normal !important; */
}
.title-result {
  text-align: center;
  font-family: KhmerOsMuol !important;
  font-weight: 100 !important;
  font-size: 13px !important;
  letter-spacing: normal !important;
  margin-top: 5px;
}
.title-En {
  text-align: center;
  font-weight: 100 !important;
  font-size: 13px !important;
  line-height: 180% !important;
  font-family: GothicBoldRegular !important;
}
.tackteng-font {
  display: flex;
  justify-content: center;
}
.image-tackteng {
  width: 200px;
}
.table-monthly-result {
  /* // border: 1px solid $primary-color; */
  width: 100%;
  border-collapse: collapse;
}
.thead-monthly-result {
  border: 1px solid #0f81c2;
  background-color: #60c5ff38;
  font-size: 12px;
  font-family: KhmerOsMuol !important;
  font-weight: 100 !important;
  text-align: center;
}
.tbody-monthly-result {
  border: 1px solid #0f81c2;
  font-size: 12px;
  font-family: Siemreap !important;
  text-align: center;
}
.table-footer {
  font-size: 11px !important;
  font-family: Siemreap !important;
  font-weight: 100;
  text-align: center;
  background-color: #60c5ff38;
}
.table-footer-white-bg {
  font-size: 11px !important;
  font-family: Siemreap !important;
  font-weight: 100;
  text-align: center;
}
.footer-agree {
  font-size: 13px !important;
  font-family: Siemreap !important;
  text-align: center !important;
}
.footer-bold {
  font-size: 12px !important;
  font-family: KhmerOsMuol !important;
  font-weight: 100 !important;
  text-align: center !important;
}
.footer-date {
  font-size: 12px !important;
  font-family: Siemreap !important;
  text-align: center !important;
}
.footer-name {
  font-size: 12px !important;
  font-family: KhmerOsMuol !important;
  font-weight: 100 !important;
  margin-top: 60px !important;
  margin-left: 60px !important;
  text-align: center;
}
.field-name {
  background-color: #fff;
  border-radius: 6px;
  font-family: KhmerOsMuol !important;
  font-weight: 100 !important;
  font-size: 12px !important;
  .MuiOutlinedInput-input {
    font-family: KhmerOsMuol !important;
    font-weight: 100 !important;
    font-size: 12px !important;
    margin-left: 60px !important;
  }

  .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: KhmerOsMuol !important;
    font-weight: 100 !important;
    font-size: 12px !important;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
    font-family: KhmerOsMuol !important;
    font-weight: 100 !important;
    border-radius: 6px;
    font-size: 12px !important;
  }
}
/*==============================Certificate Foriegn language =============================*/

.body-cert-en-pro {
  font-size: 16px !important;
  font-family: Century !important;
  /* font-weight: 600 !important; */
}

.title-cert-att {
  font-size: 22px !important;
  font-family: Century !important;
  font-weight: bold !important;
}

.body-cert-en-pro-bold {
  font-size: 16px !important;
  font-family: Century !important;
  font-weight: bold !important;
}

/*==============================End certificate enrollment student =================================================================*/

/* =========================================Start print school fee========================================= */

tfoot.report-footer-sch-fee {
  display: table-footer-group;
}

div.footer-info-sch-fee,
div.page-footer-sch-fee {
  display: none;
  height: 40px;
}
.page-footer-line-sch-fee {
  border: 2px solid blue;
  margin-bottom: 7px;
  width: 86.5%;
}

div.page-footer-text-sch-fee {
  text-align: center;
  color: blue;
  font-family: "Khmer OS Siemreap";
  font-size: 10px !important;
}
table.report-container-sch-fee {
  page-break-after: always;
  width: 100% !important;
  /* display: table;
  overflow-x: auto; */
  /* white-space: nowrap; */
  border-spacing: 0px !important;
}

div.page-footer-sch-fee {
  text-align: center;
  height: 50px;
  font-size: 10px;
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-right: 20px;
}

.table-student-parents td {
  border: 1px solid #92cbf9;
  font-size: 9px;
  font-family: Siemreap !important;
  font-weight: 100 !important;
  text-align: center;
}

.table-student-parents th {
  background-color: #deeffd;
}

/* .tbody-student-parents{

} */

.start-date-student-parents-info {
  font-family: Siemreap !important;
  font-size: 10px !important;
  font-weight: 100 !important;
}
/* =============================footer for print=========================== */
