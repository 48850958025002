@import "../themes.scss";

.page-container {
  .tabs-containner {
    margin-top: 30px;
    border-bottom: 1px solid rgb(216, 215, 215);
    // background-color: #fff;

    .active-btn-tab {
      font-family: $font-kantumruy-pro;
      font-size: $font-size-subtitle;
      font-weight: bold;
      padding: 6px 20px;
      color: $primary-color;
      border-bottom: 2px solid $primary-color;
      border-radius: 0px !important;
    }
    .btn-tab {
      font-family: $font-kantumruy-pro;
      font-size: $font-size-subtitle;
      font-weight: bold;
      padding: 6px 20px;
      color: $primary-color;
    }
  }

  .page-header {
    height: 40px;
    width: 100%;

    .slash {
      height: 40px;
      width: 4px;
      background-color: $primary-color;
    }

    .page-title {
      font-family: $font-kantumruy-pro;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold !important;
      text-decoration: none;
    }

    .breadcrumbs-title {
      font-family: $font-kantumruy-pro;
      color: black;
      font-size: $font-size-body;
    }

    .slash-title {
      font-family: $font-kantumruy-pro;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold;
    }

    .page-link-title {
      font-family: $font-kantumruy-pro;
      color: $primary-color;
      font-size: $font-size-body;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .header-text {
    font-size: $font-size-body;
    font-family: $font-kantumruy-pro;
    color: $primary-color;
    padding: 0px 0px 5px 5px;
    margin-top: -5px;
  }

  // =================== end details page ===================

  .search-field {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    font-family: $font-kantumruy-pro;
    .css-nu79wc-MuiInputAdornment-root {
      color: $primary-color !important;
      border: none;
    }

    .css-e2mtqw-MuiInputAdornment-root {
      color: $primary-color !important;
      border: none;
    }

    .MuiOutlinedInput-input {
      color: $primary-color;
      font-family: $font-kantumruy-pro;
      border: none;
    }

    .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy-pro;
      color: $primary-color;
      border: none;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: none;
      font-family: $font-kantumruy-pro;
      border-radius: 6px;
      color: $primary-color;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: $primary-color !important;
      font-family: $font-kantumruy-pro !important;
      border-radius: 6px !important;
      border: none;
    }

    .MuiInputLabel-root.Mui-focused {
      color: $primary-color;
      font-family: $font-kantumruy-pro;
      border-radius: 6px;
      border: none;
    }
  }

  .filter-date {
    background-color: #229751;
  }

  .field-title {
    font-family: $font-kantumruy-pro;
    color: $primary-color;
    font-size: $font-size-body;
  }

  .btn-create {
    font-family: $font-kantumruy-pro;
    font-size: $font-kantumruy-pro;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 10px;
    padding: 8px 30px;
    margin-top: 20px;

    .icon-add {
      width: 18px;
      height: 18px;
    }
  }

  .body-container {
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    padding: 0px 0px 20px 0px;

    @include mobile-small {
      width: 310px !important;
    }

    @include mobile {
      width: 370px !important;
    }

    @include mobile-large {
      width: 476px !important;
    }

    .table {
      width: 100%;
      display: table;
      overflow-x: auto;
      white-space: nowrap;
      border-collapse: separate;
      border-spacing: 0px 8px;

      .header-row {
        background-color: #fff;
        padding: 30px 0px;

        .header-title-start {
          border-radius: 6px 0px 0px 6px;
          padding: 10px 5px 10px 20px;
          border: none;
          font-family: $font-kantumruy-pro;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }

        .header-title {
          padding: 10px 5px;
          border: none;
          font-family: $font-kantumruy-pro;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }

        .header-title-end {
          border-radius: 0px 6px 6px 0px;
          padding: 10px 20px 10px 5px;
          border: none;
          font-family: $font-kantumruy-pro;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }

        .header-sticky-left {
          position: sticky;
          left: 0;
          background-color: #fff;
        }

        .header-sticky-right {
          position: sticky;
          right: 0;
          background-color: #fff;
        }
      }

      // =================== background white =================
      .body {
        background-color: #fff;
        box-shadow: none;

        :hover {
          background-color: rgb(239, 246, 253);
          cursor: pointer;
        }

        .onclick-student {
          background-color: rgb(239, 246, 253);
        }

        .body-row {
          cursor: pointer;

          .body-cell-start {
            border: none;
            font-size: $font-size-body;
            font-family: $font-kantumruy-pro;
            padding: 5px 5px 5px 20px;
            border-radius: 6px 0px 0px 6px;
          }

          .body-cell {
            border: none;
            font-size: $font-size-body;
            font-family: $font-kantumruy-pro;
            padding: 5px;

            .member-name {
              font-size: $font-size-body;
              font-family: $font-kantumruy-pro;
            }
          }

          .body-cell-end {
            border: none;
            font-size: $font-size-body;
            font-family: $font-kantumruy-pro;
            padding: 5px 20px 5px 5px;
            border-radius: 0px 6px 6px 0px;
          }

          .body-cell-sticky-left {
            position: sticky;
            left: 0;
            background-color: #fff;
          }

          .body-cell-sticky-right {
            position: sticky;
            right: 0;
            background-color: #fff;
          }
        }
      }
    }
  }
}
