@import "../../themes.scss";

.body-container {
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    padding: 0px 0px 20px 0px;

    @include mobile-small {
      width: 310px !important;
    }

    @include mobile {
      width: 370px !important;
    }

    @include mobile-large {
      width: 476px !important;
    }

    .table {
      width: 100%;
      display: table;
      overflow-x: auto;
      white-space: nowrap;
      border-collapse: separate;
      border-spacing: 0px 8px;

      .header-row {
        background-color: #fff;
        padding: 15px 0px;

        .header-title-start {
          border-radius: 6px 0px 0px 6px;
          padding: 10px 5px 10px 20px;
          border: none;
          font-family: $font-kantumruy-pro;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }

        .header-title {
          padding: 10px 5px;
          border: none;
          font-family: $font-kantumruy-pro;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }

        .header-title-end {
          border-radius: 0px 6px 6px 0px;
          padding: 10px 20px 10px 5px;
          border: none;
          font-family: $font-kantumruy-pro;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }

        .header-sticky-left {
          position: sticky;
          left: 0;
          background-color: #fff;
        }

        .header-sticky-right {
          position: sticky;
          right: 0;
          background-color: #fff;
        }
      }

      // =================== background white =================
      .body {
        background-color: #fff;
        box-shadow: none;

        :hover {
          background-color: rgb(239, 246, 253);
          cursor: pointer;
        }

        .onclick-student {
          background-color: rgb(239, 246, 253);
        }

        .body-row {
          cursor: pointer;
          background-color: #f6f6f6;
          height: 50px;

          .body-cell-start {
            border: none;
            font-size: $font-size-body;
            font-family: $font-kantumruy-pro;
            padding: 5px 5px 5px 20px;
            border-radius: 6px 0px 0px 6px;
          }

          .body-cell {
            border: none;
            font-size: $font-size-body;
            font-family: $font-kantumruy-pro;
            padding: 5px;

            .member-name {
              font-size: $font-size-body;
              font-family: $font-kantumruy-pro;
            }
          }

          .body-cell-end {
            border: none;
            font-size: $font-size-body;
            font-family: $font-kantumruy-pro;
            padding: 5px 20px 5px 5px;
            border-radius: 0px 6px 6px 0px;
          }

          .body-cell-sticky-left {
            position: sticky;
            left: 0;
            background-color: #fff;
          }

          .body-cell-sticky-right {
            position: sticky;
            right: 0;
            background-color: #fff;
          }
        }
      }
    }
  }