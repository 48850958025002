@import "../../themes.scss";

.drawer-menu {
  .MuiDrawer-paper {
    background-color: #fff;
    // background-image: linear-gradient(to bottom, #dfe7f2, #e3eaf3, #e8eef4, #edf1f5, #f2f4f6);
    box-shadow: rgba(19, 105, 233, 0.15) 1.95px 1.95px 2.6px;
  }

  .image-container {
    margin-top: 20px;
    width: 175px;
    height: 175px;
    border-radius: 50% !important;

    .logo {
      width: 80%;
      height: 80%;
    }
  }

  .list-menu {
    .list-item-active {
      width: 100%;
      height: 50px;

      .list-item-button {
        height: 100%;

        .list-item-icon {
          justify-content: center;
          color: $primary-color;
          height: 100%;

          .icon {
            width: 22px;
            height: 22px;
            color: $primary-color;
            margin-top: 6px;
            font-weight: bold !important;
          }
        }

        .list-item-text {
          color: $primary-color;
          font-size: 16px;
          padding-top: 2px;
          font-family: $font-kantumruy-pro;
          font-weight: bold !important;
        }
      }

      .submenu-icon-active {
        color: $primary-color;
      }
    }

    .list-item {
      width: 100%;
      height: 50px;

      .list-item-button {
        height: 100%;

        .list-item-icon {
          justify-content: center;
          height: 100%;

          .icon {
            width: 22px;
            height: 22px;
            margin-top: 6px;
          }
        }

        .list-item-text {
          font-size: 16px;
          padding-top: 2px;
          font-family: $font-kantumruy-pro;
          // color: $gray-font-color;
          font-weight: bold !important;
          color: gray;
        }
      }

      .submenu-icon-active {
        color: $gray-font-color;
      }
    }
  }
}
