@import "../../../themes.scss";

.personal-info {
  margin-top: 30px;
  background-color: white;
  // padding: 10px;
  border-radius: 6px;
  // height: 650px;

  .image-list {
    width: 180px;
    height: 200px;
    object-fit: cover;
    border-radius: 15px;
  }
  .profile-name {
    font-family: $font-kantumruy-pro;
    font-size: $font-size-title;
    font-weight: bold;
    color: $primary-color;
  }
  .detial-font {
    font-family: $font-kantumruy-pro;
    font-size: $font-size-body;
    font-weight: bold;
    color: $primary-color;

    .reference-file-img{
      width: 200px;
      height: 300px;
    }
  }
}
