@import "../../themes.scss";

.alert-message {
  .snackbar-alert {
    margin-top: 10px;
    border-radius: 20px;

    .alert-success {
      border-radius: 20px;
      background-color: #00c9a7;
      color: #fff;
      font-family: $font-kantumruy-pro;
    }
    .alert-error {
      border-radius: 20px;
      background-color: #ff6f91;
      color: #fff;
      font-family: $font-kantumruy-pro;
    }
  }
}
