@import "../themes.scss";

.dollar-container {
  width: 26px;
  height: 26px;
  border-radius: 8px;
  background-color: $background-dollar !important;

  .dollar-icon {
    color: #fff;
    margin-bottom: 2px;
    font-size: 15px !important;
  }
}

.map-container {
  width: 26px;
  height: 26px;
  border-radius: 6px;

  .map-icon {
    margin-top: 2px;
    width: 22px !important;
    width: 22px !important;
  }
}

.update-container {
  width: 26px;
  height: 26px;
  border-radius: 6px;
  background-color: $primary-color !important;
  .update-icon {
    color: white !important;
    margin-bottom: 2px;
    font-size: 14px !important;
    background-color: $primary-color !important;
    // &:hover {
    //   background-color: $primary-color !important;
    // }
  }

  .doc-icon {
    color: #fff;
    margin-bottom: 2px;
    font-size: 18px !important;

    &:hover {
      background-color: $primary-color !important;
    }
  }
}

.reset-container {
  width: 26px;
  height: 26px;
  border-radius: 6px;
  border: 1px solid #357a38;
  // background-color: #357a38 !important;

  .reset-icon {
    color: #357a38;
    margin-bottom: 2px;
    font-size: 13px !important;

    // &:hover {
    //   background-color: #357a38 !important;
    // }
  }

  .doc-icon {
    color: #fff;
    margin-bottom: 2px;
    font-size: 18px !important;

    &:hover {
      background-color: #357a38 !important;
    }
  }
}

.delete-container {
  width: 26px;
  height: 26px;
  border-radius: 6px;
  background-color: $background-delete !important;
  // border: 1px solid $background-delete;

  .delete-icon {
    color: white;
    margin-bottom: 2px;
    font-size: 16px !important;
    background-color: $background-delete !important;
  }
}
.approve-container {
  width: 26px;
  height: 26px;
  border-radius: 6px;
  background-color: #fff !important;
  border: 1px solid green;

  .approve-icon {
    color: green;
    margin-bottom: 2px;
    font-size: 16px !important;
  }
}

.delete-icon {
  color: $background-delete;
  font-size: 26px !important;
}

.btn-void {
  height: 28px !important;
  border-radius: 5px !important;
  background-color: $background-delete !important;
  color: #fff !important;
  padding: 2px 10px !important;
  font-family: $font-kantumruy-pro;
  font-size: 13px;
}

.update-title {
  font-family: $font-kantumruy-pro;
  font-size: $font-size-body !important;
}
