@import "../../../themes.scss";
.container {
  padding: 25px;
  margin-top: 10px;

  .header {
    font-family: $font-khmer-os-moul;
    font-size: 12px;
  }
  .headerEng {
    text-align: center;
    font-weight: 100 !important;
    font-size: 12px !important;
    line-height: 180% !important;
    font-family: GothicBoldRegular !important;
  }
  .image {
    width: 180px;
    height: 180px;
  }
  .imageSymbol {
    width: 20%;
  }
}
.thead-text-dusburment {
  font-family: "Khmer OS Siemreap" !important;
  font-size: 13px !important;
  letter-spacing: normal !important;
  font-weight: 100 !important;
}
.table-container-main {
  width: 100% !important;
  border: 2px solid #81a8cf;
  border-collapse: collapse;
}
.table-container-main th {
  // border: 2px solid #81a8cf;
  border-top: 2px solid #81a8cf;
  border-bottom: 2px solid #81a8cf;
  border-left: 1px solid #81a8cf;
  border-right: 1px solid #81a8cf;
  background-color: #d2e2f2;
  font-family: $font-siemreap;
  font-size: 12px;
}
.table-container-main td {
  border: 1px solid #81a8cf;

  font-family: $font-siemreap;
  font-size: 12px;
}
.table-container-main1 {
  width: 100% !important;
  // border:2px solid #81A8CF;
  border-collapse: collapse;
  border-bottom: 2px solid #81a8cf;
  //   border-left: 2px solid #81a8cf;
  //   border-right: 2px solid #81a8cf;
}
.table-container-main1 th {
  // border: 2px solid #81A8CF;
  // border-top: 2px solid #81A8CF;
  border-bottom: 2px solid #81a8cf;
  border-left: 1px solid #81a8cf;
  border-right: 1px solid #81a8cf;
  background-color: #d2e2f2;
  font-family: $font-siemreap;
  font-size: 12px;
}
.table-container-main1 td {
  border: 1px solid #81a8cf;
  font-family: $font-siemreap;
  font-size: 12px;
}

.font {
  font-family: $font-siemreap;
  font-size: 12px !important;
}
.fontKh {
  font-family: $font-khmer-os-moul;
  font-size: 12px !important;
}

.cicle-box {
  border-radius: 50%;
  background-color: white;
  height: 20px;
  width: 25px;
  border: 1px solid #000;
}
.icon-check {
  position: absolute;
  top: 1px;
  left: 4px;
}
