@import "../themes.scss";

.dialog-container {
  .MuiPaper-root {
    border-radius: 10px !important;
  }

  .dialog-title {
    font-size: $font-size-title;
    color: $primary-color;
    font-weight: bold;
    font-family: $font-kantumruy-pro;
  }
  .dialog-title-view {
    font-size: $font-size-title;
    color: $primary-color;
    font-weight: bold;
    font-family: $font-kantumruy-pro;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .delete-descript {
    font-family: $font-kantumruy-pro;
  }

  .close-icon {
    color: red;
  }

  .avater-image {
    width: 140px;
    height: 140px;
    object-fit: cover !important;
  }

  .student-image {
    width: 150px;
    height: 150px;
  }

  .image-title {
    font-family: $font-kantumruy-pro;
    font-size: $font-size-body;
    color: $primary-color;
    text-align: center;
  }

  .field-title {
    font-family: $font-kantumruy-pro;
    font-size: $font-size-body;
    color: $primary-color;
    margin-bottom: 2px;
  }

  .text-field {
    font-family: $font-kantumruy-pro !important;
    .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy-pro;
    }
    .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy-pro;
    }
    .css-1ootu8q-MuiButtonBase-root-MuiMenuItem-root {
      font-family: $font-kantumruy-pro;
    }
  }

  .btn-add {
    background-color: $primary-color !important;
    font-family: $font-kantumruy-pro;
    font-size: $font-size-body;
    border-radius: 5px;
    height: 32px;
    color: #fff;
  }

  .btn-create {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 8px;
    font-family: $font-kantumruy-pro;
  }

  .btn-delete {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: $background-delete !important;
    color: #fff;
    border-radius: 8px;
    font-family: $font-kantumruy-pro;
    font-weight: bold;
  }
  .btn-cancel {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: #e53838 !important;
    color: #fff;
    border-radius: 8px;
    font-family: $font-kantumruy-pro;
    font-weight: bold;
  }
  .btn-approve {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: #00d387 !important;
    color: #fff;
    border-radius: 8px;
    font-family: $font-kantumruy-pro;
    font-weight: bold;
  }

  .btn-upload-user {
    width: 160px;
    height: 160px;
    border-radius: 50%;

    .avater-image {
      width: 160px;
      height: 160px;
      object-fit: cover;
      border-radius: 50%;
    }

    .image-title {
      font-family: $font-kantumruy-pro;
      font-size: $font-size-body;
      color: $primary-color;
      text-align: center;
    }
  }
  .btn-upload-doc {
    width: 100px;
    height: 120px;
  }
  .btn-upload {
    width: 100px;
    height: 90px;
  }
  .image-list {
    width: 100px;
    height: 90px;
    object-fit: cover;
  }

  .title-view {
    font-size: 16px;
    font-family: $font-khmer-os-moul;
    text-align: center;
  }

  .subtitle-view {
    font-family: $font-kantumruy-pro;
    text-align: center;
    font-size: 16px;
  }
  .body-view {
    font-size: 14px;
    font-family: $font-kantumruy-pro;
  }
  .body-view-time {
    font-size: 14px;
    font-family: $font-kantumruy-pro;
    color: $primary-color;
  }
  .App {
    text-align: center;
    width: 100%;
    // height: 200px;
    // background-color: red;
    .App-header {
      // background-color: #282c34;
      background-color: #fff;
      min-height: 65vh;
      // height: auto;
      width: 100%;
      // display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // font-size: calc(10px + 2vmin);
      // color: white;
      color: #7474748c;
      // border: 1px solid;
    }
  }
  .text-field {
    .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy-pro;
    }
    .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy-pro;
    }
    .css-1ootu8q-MuiButtonBase-root-MuiMenuItem-root {
      font-family: $font-kantumruy-pro;
    }
  }
}
